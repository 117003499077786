<script>
const API_URL = "https://pdouga.sompo-japan.co.jp/sj-pdouga/";
const SUNDAY_SKY_VIDEO_ID = "ef80e024-76e2-4be0-9bf8-f9810ddb0bad";
const SUNDAY_SKY_API_URL = `https://${SUNDAY_SKY_VIDEO_ID}.web.sundaysky.com/create_video_session`;
const SUNDAY_SKY_SURVEY_ID = "5033555";
const SUNDAY_SKY_SURVEY_SHOW_AT = "0.25";
const PLAYER_EVENT_SCENE_NAME = "PremiumOverview";
export default {
  name: "GlobalComponents",
  API_URL,
  SUNDAY_SKY_VIDEO_ID,
  SUNDAY_SKY_API_URL,
  SUNDAY_SKY_SURVEY_ID,
  SUNDAY_SKY_SURVEY_SHOW_AT,
  PLAYER_EVENT_SCENE_NAME,
};
</script>
