import { createRouter, createWebHistory } from "vue-router";
import PersonalizedVideo from "../views/PersonalizedVideo.vue";

const routes = [
  {
    path: "/personalized-video",
    name: "PersonalizedVideo",
    component: PersonalizedVideo,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
