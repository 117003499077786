<template>
  <div class="background">
    <div class="div-2" v-if="mode == 'pc'">
      ご視聴いただきありがとうございます
    </div>
    <div class="div-2" v-if="mode == 'sp'">
      ご視聴いただき<br />ありがとうございます
    </div>
    <div class="div-3" v-if="mode == 'pc'">
      保険をお使いになられた場合の次年度以降の保険料について、<br />
      お客さま専用の案内動画をご用意しました。<br />
      保険をお使いになられた場合とお使いになられなかった場合の差額を、<br />
      簡単に動画で確認できます。ぜひご視聴ください。
    </div>
    <div class="div-3" v-if="mode == 'sp'">
      保険をお使いになられた場合の<br />次年度以降の保険料について、<br />
      お客さま専用の案内動画をご用意しました。<br />
      保険をお使いになられた場合と<br />お使いになられなかった場合の差額を、<br />
      簡単に動画で確認できます。ぜひご視聴ください。
    </div>
    <div class="div-4">※動画視聴時の通信料はお客さま負担となります。</div>
    <div>
      <div id="ssky-elements" class="ssky-elements">
        <div class="sskycontainer" id="sskycontainer"></div>
        <div id="sundaySkyPlayerWrap">
          <!-- player -->
          <div class="player_bg">
            <div id="outlineDiv">
              <div id="sundayskyDiv"></div>
            </div>
          </div>
        </div>
        <!-- survey -->
        <div id="surveyContainer" class="survey-container"></div>
      </div>
      <div class="footer-main"><br />推奨環境<br /></div>
      <div class="footer-sub">
        PC：Windows 10 以上/Mac OS 13.0 以上
        <br />
        Mobile：iOS 15 以上/Android 11 以上
        <br />
        ブラウザ：Google Chrome, Firefox, Microsoft Edge,
        Safari（それぞれ最新版）
        <br /><br />
        ただし、上記環境範囲内であっても、ブラウザとOSの組み合わせやインターネット接続状況により、
        <br />
        ご利用になれない場合がこざいます。あらかじめご了承ください。
      </div>
    </div>
  </div>
</template>

<script>
import Global from "@/const/Global.vue";
/**
 * 動画再生画面
 */
export default {
  name: "PersonalizedVideo",
  props: {
    mode: String,
  },
  /**
   * methods
   */
  methods: {
    /**
     * 動画パラメータを取得
     *
     * @return {Object} 返却されたJSON
     */
    async playVideos() {
      try {
        const response = await fetch(
          `${Global.API_URL}play-videos/${this.$route.query.id}`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
            },
          }
        );
        if (response.status != 200) {
          throw new Error(
            "動画パラメータを取得するAPIで200番以外が返却されています。"
          );
        }
        const resBody = await response.json();
        return resBody;
      } catch (error) {
        throw new Error("動画パラメータを取得するAPIでエラーが発生しました。");
      }
    },
    /**
     * サンデースカイ側にパラメータを渡す
     *
     * @param {Object} resBody
     * @return {Object} 返却されたbase64文字列
     */
    async sundaySkyApi(resBody) {
      var session;

      try {
        const sundayResponse = await fetch(Global.SUNDAY_SKY_API_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: resBody.id,
            NameLast: resBody.nameLast,
            NameLastKana: resBody.nameLastKana,
            InsuranceStartYear: Number(resBody.insuranceStartYear),
            InsuranceStartMonth: String(resBody.insuranceStartMonth),
            InsurancePeriod: Number(resBody.insurancePeriod),
            FleetRateLast: String(resBody.fleetRateLast),
            FleetRateNew: String(resBody.fleetRateNew),
            AccidentCountPeriod: String(resBody.accidentCountPeriod),
            PremiumLast: Number(resBody.premiumLast),
            PremiumNew: Number(resBody.premiumNew),
            PremiumDifference: Number(
              resBody.premiumDifference
            ).toLocaleString(),
            SimulationCalculationYears: Number(resBody.calculationYears),
            AgentName: resBody.agencyName,
            ExceptFirstContact: resBody.exceptFirstContact,
            ClaimedPreviously: resBody.previousAccident,
            PolicyType: resBody.policyType,
          }),
        });
        if (sundayResponse.status != 200) {
          throw new Error();
        }

        await sundayResponse.text().then((value) => {
          session = value;
        });

        return session;
      } catch (error) {
        throw new Error();
      }
    },
    /**
     * 動画視聴APIを呼び出す
     *
     * @param {Object} resBody
     */
    async watchedMovie(resBody) {
      try {
        await fetch(`${Global.API_URL}watched-movie`, {
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: resBody.id,
        });
      } catch (error) {
        // 何もしない
      }
    },
  },
  mounted() {
    this.$nextTick(function () {
      const self = this;
      async function createPlayer() {
        var session;

        try {
          // 動画パラメータを取得
          const resBody = await self.playVideos();

          // IDが不正の場合
          if (
            resBody.id === null ||
            typeof resBody.id === "undefined" ||
            resBody.id === ""
          ) {
            document.getElementsByClassName("sskycontainer")[0].innerHTML =
              '<div class="error-image-box"><img src="/img/error_image_sompo.png" class="error-image"></img></div>';
            return false;
          }

          // 削除済の場合
          if (resBody.deletedAt) {
            document.getElementsByClassName("sskycontainer")[0].innerHTML =
              '<div class="error-image-box"><img src="/img/delete_image_sompo.png" class="error-image"></img></div>';
            return false;
          }

          // 初期画像
          document.getElementsByClassName("sskycontainer")[0].innerHTML =
            '<div class="error-image-box"><img src="/img/poster.png" class="error-image"></img></div>';

          // サンデースカイ側にパラメータを渡す
          session = await self.sundaySkyApi(resBody);

          // 初期画像を削除
          document.getElementsByClassName("sskycontainer")[0].innerHTML = "";

          const sfid = Global.SUNDAY_SKY_VIDEO_ID;

          // プレイヤー
          var sskyplayer = document.createElement("sundaysky-video");
          sskyplayer.setAttribute("id", "sskyplayer");
          sskyplayer.setAttribute("analytics-token", sfid);
          sskyplayer.setAttribute("session", session);
          sskyplayer.setAttribute("poster", "/img/poster.png");
          sskyplayer.setAttribute("pre-roll", "/img/preroll.gif");
          sskyplayer.setAttribute("no-captions", "");

          // プレイヤーイベント
          sskyplayer.addEventListener("scene", async function (event) {
            if (event.detail.scene.name === Global.PLAYER_EVENT_SCENE_NAME) {
              await self.watchedMovie(resBody);
            }
          });

          document.getElementById("sskycontainer").appendChild(sskyplayer);
        } catch (error) {
          document.getElementsByClassName("sskycontainer")[0].innerHTML =
            '<div class="error-image-box"><img src="/img/error_image_sompo.png" class="error-image"></img></div>';
          return false;
        }
      }
      createPlayer();
    });
  },
};
</script>

<style scoped>
@import "./style/PersonalizedVideo.css";
</style>
