<template>
  <div>
    <HeaderTemplate :mode="mode" />
    <router-view :mode="mode" />
    <FooterTemplate :mode="mode" />
  </div>
</template>

<script>
import HeaderTemplate from "./components/HeaderTemplate.vue";
import FooterTemplate from "./components/FooterTemplate.vue";
import _ from "lodash";
export default {
  name: "app",
  components: {
    HeaderTemplate,
    FooterTemplate,
  },
  data() {
    return {
      width: window.innerWidth,
      mode: "pc",
    };
  },
  methods: {
    // 画面リサイズ時に発火
    // lodash の debounce を使用して、200msごとに発火させる
    handleResize: _.debounce(function () {
      this.width = window.innerWidth;
      this.mode = window.innerWidth > 768 ? "pc" : "sp";
    }, 200),
  },
  mounted() {
    this.mode = window.innerWidth > 768 ? "pc" : "sp";
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style>
#app {
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ",
    Meiryo, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2b2b2b;
  line-height: normal;
}
</style>
